import { getApiPlatformList } from '@/apiForManage/aggregate'
import {
  getAppListForManage,
  getAdminList,
  getPlatListByuser,
  getAppListByAdminId,
  getPlaceListByAppIds,
  getGroupList,
  groupListByAdplace,
  getSourceList,
  getChannelList,
  getPositionList,
  getSourceListByAdplace,
  getProdAndpackageList,
  continentList,
  getTrafficBundleList,
  getPlatPositionList,
  getDspList,
  getAdminUserList,
  getDspTaskList,
  getCrowdList
} from '@/apiForManage/common'
import { getAppList, getAppLists } from '@/apiForManage/media'
import { getSupplierList } from '@/apiForManage/flow/supplier'
import { getPlaceList } from '@/apiForManage/flow/place'
export default {
  data () {
    return {
      // 供应商
      supplierList: [],
      trafficBundleList: [],
      platList: [],
      adminList: [],
      appList: [],
      appLists: [],
      placeList: [],
      groupList: [],
      sourceList: [],
      channelList: [],
      positionList: [],
      packageList: [],
      productList: [],
      areaTreeList: [],
      positionNameList: [],
      dspList: [],
      adminUserList: [],
      dspTaskList: [],
      crowdList: [],
      // 操作系统列表
      osList: [
        {
          name: '安卓',
          id: '1'
        },
        {
          name: 'IOS',
          id: '0'
        }
      ],
      // 素材类型列表
      typeList: [
        {
          name: '图片',
          id: '1'
        },
        {
          name: '视频',
          id: '2'
        }
      ],
      stsList: [
        { name: '开启', id: 'A' },
        { name: '关闭', id: 'S' }
      ]
    }
  },
  methods: {
    // 获取dsp广告源列表
    async getDspList (platIds = []) {
      const { data = [] } = await getDspList({
        platAccountIdList: [],
        platIdList: platIds
      })
      data.forEach((item) => {
        item.searchId = item.platPlaceId
        item.id = '' + item.id
      })
      this.dspList = data
    },
    // 应用包名列表
    async getTrafficBundleList () {
      const { data = [] } = await getTrafficBundleList()
      data.forEach((item) => {
        item.id = item.bundle
        item.searchId = item.bundle
      })
      this.trafficBundleList = data
    },
    // 获取供应商列表
    async getSupplierList (userIdList = []) {
      const { data = [] } = await getSupplierList({
        userIdList
      })
      this.supplierList = data
    },

    // 获取全部列表
    async getAreaTreeList () {
      const resp = await continentList()
      this.areaTreeList = resp.data || []
    },
    // 获取全部广告平台列表
    async getPlatList () {
      const resp = await getApiPlatformList()
      if (resp.code === 200) {
        this.platList = resp.data || []
      }
    },
    // 获取账户列表
    async getAdminList () {
      const resp = await getAdminList()
      if (resp.code === 200) {
        this.adminList = resp.data || []
      }
    },
    // 获取全部应用列表
    async getAppLists (data) {
      const resp = await getAppLists(data)
      this.appLists = resp.data || []
    },
    // 获取全部应用列表
    async getAppList () {
      const resp = await getAppList()
      this.appList = resp.data || []
    },
    // 获取全部应用列表(后台)
    async getAppListForManage (data = {}) {
      const resp = await getAppListForManage(data)
      this.appList = resp.data || []
    },
    // 获取全部广告位列表
    async getPlaceList (publisherIdList = []) {
      const { data = [] } = await getPlaceList({ publisherIdList })
      data.forEach((item) => {
        item.searchId = item.customPlaceId || item.id
      })
      this.placeList = data
    },

    // 获取全部流量分组列表
    async getGroupList () {
      const resp = await getGroupList()
      this.groupList = resp.data || []
    },
    // 获取全部广告源位列表
    async getSourceList () {
      const resp = await getSourceList()
      this.sourceList = resp.data || []
      this.sourceList.forEach((item) => {
        item.searchId = item.platPlaceId
      })
    },
    // 获取渠道列表
    async getChannelList () {
      const resp = await getChannelList()
      this.channelList =
        resp.data.map((item) => ({
          id: item,
          name: item
        })) || []
    },

    // 获取广告样式列表
    async getPositionList () {
      const resp = await getPositionList()
      this.positionList = resp.data || []
    },
    // 获取账户列表
    async getAdminUserList () {
      const resp = await getAdminUserList()
      this.adminUserList = (resp.data || []).map((item) => ({
        name: item.username,
        id: item.id,
        ...item
      }))
    },
    // 获取广告主任务列表
    async getDspTaskList () {
      const resp = await getDspTaskList()
      this.dspTaskList = (resp.data || []).map((item) => ({
        name: item,
        id: item
      }))
    },
    // 获取人群包列表
    async getCrowdList () {
      const { data = [] } = await getCrowdList()
      this.crowdList = data
    },
    // 关联查询
    // 通过账户获取广告平台列表
    async getPlatListByuser () {
      const resp = await getPlatListByuser()
      if (resp.code === 200) {
        this.platList = resp.data || []
      }
    },
    // 通过账户查应用列表
    async getAppListByAdminId (id) {
      const resp = await getAppListByAdminId(id)
      if (resp.code === 200) {
        this.appList = resp.data || []
      }
    },
    // 通过应用id（数组）查广告位列表
    async getPlaceListByAppIds (ids = []) {
      const resp = await getPlaceListByAppIds(ids)
      if (resp.code === 200) {
        this.placeList = resp.data || []
      }
    },
    // 通过placeid（多个）查group列表
    async groupListByAdplace (ids = []) {
      const resp = await groupListByAdplace(ids)
      if (resp.code === 200) {
        this.groupList = resp.data || []
      }
    },
    // 通过广告位查询广告源
    async getSourceListByAdplace (ids = []) {
      const resp = await getSourceListByAdplace(ids)
      if (resp.code === 200) {
        this.sourceList = resp.data || []
        this.sourceList.forEach((item) => {
          item.searchId = item.platPlaceId
        })
      }
    },
    async getProdAndpackageList () {
      const { data = {} } = await getProdAndpackageList()
      const { productList = [], packageList = [] } = data
      this.packageList = packageList.map((item) => {
        return {
          id: item.productBundle,
          name: item.productName
        }
      })
      this.productList = productList.map((item) => {
        return {
          name: item,
          id: item
        }
      })
    },
    async getPlatPositionList () {
      const resp = await getPlatPositionList()
      if (resp.code === 200) {
        const { data = [] } = resp
        this.positionNameList = data.map((item) => ({ name: item, id: item }))
      }
    }
  }
}
